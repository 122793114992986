import React, { useEffect, useRef, useState, ReactNode } from 'react';
import KelvinsTitleBox from '../KelvinsTitleBox';
import ContentWrapper from '../ContentWrapper';

const PageWrapper = ({ children }: { children: ReactNode }) => {
    const [usingMobile, setUsingMobile] = useState<boolean>(false);

    const windowWidth = useRef(window.innerWidth);

    useEffect(() => {
        if (windowWidth.current < 850) {
            setUsingMobile(true);
        } else {
            setUsingMobile(false);
        }
    }, [windowWidth]);

    return (
        <div
            id="PageWrapper"
            style={{
                display: 'flex',
                flexDirection: usingMobile ? 'column' : 'row',
                justifyContent: 'center',
                alignItems: 'center',
                height: usingMobile ? 'auto' : '90%',
            }}
        >
            {!usingMobile && <KelvinsTitleBox usingMobile={usingMobile}></KelvinsTitleBox>}
            <ContentWrapper usingMobile={usingMobile}>{children}</ContentWrapper>
        </div>
    );
};

export default PageWrapper;
