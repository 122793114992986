import LogInSignUpForm from '../LogInSignUpPage';
import PageWrapper from '../../components/PageWrapper';

const HomePage = () => {
    return (
        <PageWrapper>
            <LogInSignUpForm></LogInSignUpForm>
            {/* <div
                style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        fontFamily: 'DunbarTextExBold, sans-serif',
                        fontWeight: '500',
                        fontSize: '50',
                        textAlign: 'center',
                        marginBottom: '10%',
                    }}
                >
                    Undergoing Maintenance
                </div>

                <div style={{ fontFamily: 'DunbarText, sans-serif', fontWeight: '400', fontSize: '24' }}>
                    Check back in a few days
                </div>
            </div> */}
        </PageWrapper>
    );
};

export default HomePage;
