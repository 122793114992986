import { MuiTelInput } from 'mui-tel-input';

type props = {
    phoneNumber: string;
    setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
    validPhoneNumber: boolean | null;
};

const PhoneNumberInput = ({ phoneNumber, setPhoneNumber, validPhoneNumber }: props) => {
    return (
        <MuiTelInput
            onlyCountries={['US', 'CA']}
            defaultCountry="CA"
            forceCallingCode
            autoComplete="off"
            value={phoneNumber}
            onChange={(v) => setPhoneNumber(v)}
            className="MuiTelInput-width-large"
            sx={{
                '& .MuiOutlinedInput-root': {
                    height: '79px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& .MuiInputBase-input': {
                        fontFamily: 'Basic',
                        fontSize: '32px',
                    },
                },
            }}
        />
    );
};

export default PhoneNumberInput;
