import { FormEvent, useState } from 'react';
import VerificationInput from './VerificationInput';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store';
import * as sessionActions from '../../store/session';
import { fetch_wrapper } from '../../utils/fetch_wrapper';

type props = {
    secondsLeft: number;
    phoneNumber: string;
    sendVerifText: () => void;
    setSecondsLeft: React.Dispatch<React.SetStateAction<number>>;
    resendSeconds: number;
};

const VerificationForm = ({ secondsLeft, phoneNumber, sendVerifText, setSecondsLeft, resendSeconds }: props) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [verificationCode, setVerificationCode] = useState('');
    const [verifSuccess, setVerifSuccess] = useState<boolean | null>(null);

    const handleVerificationSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (verificationCode) {
            fetch_wrapper(`/text-verification-check`, {
                method: 'POST',
                body: JSON.stringify({ phone_number: phoneNumber, verification_code: verificationCode }),
                credentials: 'include',
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 'success') {
                        setVerifSuccess(true);
                        return true;
                    } else {
                        setVerifSuccess(false);
                        return false;
                    }
                })
                .then((result) => {
                    if (result) {
                        dispatch(sessionActions.sessionValidated());
                        navigate(`/userinfo`);
                    }
                });
        }
    };

    const resendVerifCode = () => {
        setSecondsLeft(resendSeconds);
        sendVerifText();
    };

    return (
        <form
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '0px',
                height: '37.5%',
            }}
            onSubmit={handleVerificationSubmit}
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                    style={{
                        display: 'flex',
                        width: '95%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            fontFamily: 'Times, sans-serif',
                            fontWeight: '400',
                            fontSize: '20px',
                            marginBottom: '5px',
                        }}
                    >
                        VERIFICATION CODE
                    </div>
                    {!verifSuccess && verifSuccess !== null && (
                        <div
                            className="font-13"
                            style={{
                                color: 'red',
                                width: '29%',
                                textAlign: 'center',
                            }}
                        >
                            Invalid Code
                        </div>
                    )}
                    {verifSuccess && (
                        <div
                            className="font-13"
                            style={{
                                color: 'green',
                                width: '40%',
                                textAlign: 'center',
                            }}
                        >
                            Verification Successful
                        </div>
                    )}
                </div>

                <VerificationInput
                    verificationCode={verificationCode}
                    setVerificationCode={setVerificationCode}
                    verifSuccess={verifSuccess}
                />
            </div>

            <div
                style={{
                    height: '43px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    fontFamily: 'Basic',
                    fontSize: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    visibility: !secondsLeft ? 'hidden' : 'visible',
                }}
            >
                Please wait {secondsLeft} seconds before resending verification code
            </div>

            {/* {verifSuccess && <div>Verification Successful</div>}
            {!verifSuccess && verifSuccess !== null && <div>Invalid Verification Code</div>} */}

            <div
                className="button-width-short-container"
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: '79px',
                }}
            >
                {!verifSuccess && (
                    <>
                        <button
                            disabled={!!secondsLeft}
                            onClick={resendVerifCode}
                            className="button-width-short"
                            style={{
                                fontSize: '20px',
                                fontFamily: 'Basic',
                                backgroundColor: !!secondsLeft ? '#808080' : '#000000',
                                color: '#FFFFFF',
                                height: '79px',
                                border: '1px',
                                cursor: !secondsLeft ? 'pointer' : 'default',
                            }}
                        >
                            Resend Verification Code
                        </button>

                        <button
                            type="submit"
                            disabled={!(verificationCode.toString().length === 5)} //62px left
                            className="button-width-short"
                            style={{
                                fontSize: '20px',
                                fontFamily: 'Basic',
                                backgroundColor: verificationCode.toString().length === 5 ? '#000000' : '#808080',
                                color: '#FFFFFF',
                                height: '79px',
                                border: '1px',
                                cursor: verificationCode.toString().length === 5 ? 'pointer' : 'default',
                            }}
                        >
                            Submit
                        </button>
                    </>
                )}
            </div>
        </form>
    );
};

export default VerificationForm;
