import React, { FormEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import PageWrapper from '../../components/PageWrapper';
import { fetch_wrapper } from '../../utils/fetch_wrapper';

type Utility = {
    full_name: string;
    id: string;
    is_available: boolean;
    logo_url: string;
    slug: string;
    website_url: string;
    type: string;
};

const createUpdateUtilCreds = async (userData: { username: string; password: string; utility_id: string }) => {
    const response = await fetch_wrapper(`/create-update-util-creds`, {
        method: 'POST',
        body: JSON.stringify(userData),
        credentials: 'include',
    });
    const data = await response.json();
    return data;
};

const scrapeHistoricalData = (utilityId: string) => {
    fetch_wrapper(`/scrape-historical-data`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ utility_id: utilityId }),
    });
};

const UtilCredsPage = ({ validSession }: { validSession: Boolean;}) => {
    const [eleUsername, setEleUsername] = useState('');
    const [elePassword, setElePassword] = useState('');
    const [eleUtilities, setEleUtilities] = useState<Map<string, Utility> | null>(null);
    const [eleUtilId, setEleUtilId] = useState<string | undefined>('');
    const [eleFetching, setEleFetching] = useState(false);
    const [validEleCreds, setEleValidCreds] = useState(false);
    const [haveEleResults, setEleHaveResults] = useState(false);

    const [gasUsername, setGasUsername] = useState('');
    const [gasPassword, setGasPassword] = useState('');
    const [gasUtilities, setGasUtilities] = useState<Map<string, Utility> | null>(null);
    const [gasUtilId, setGasUtilId] = useState<string | undefined>('');
    const [gasFetching, setGasFetching] = useState(false);
    const [validGasCreds, setGasValidCreds] = useState(false);
    const [haveGasResults, setGasHaveResults] = useState(false);

    const handleEleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (eleUtilId && eleUsername && elePassword) {
            setEleFetching(true);
            createUpdateUtilCreds({
                username: eleUsername.trim(),
                password: elePassword,
                utility_id: eleUtilId,
            }).then((data) => {
                if (data.updated && eleUtilId) {
                    if (eleUtilities?.get(eleUtilId)?.is_available) {
                        fetch_wrapper(`/verify-utility-credentials`, {
                            method: 'POST',
                            body: JSON.stringify({
                                utility_id: eleUtilId,
                            }),
                            credentials: 'include',
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                setEleFetching(false);
                                setEleHaveResults(true);
                                if (data.is_successful) {
                                    setEleValidCreds(true);
                                    scrapeHistoricalData(eleUtilId);
                                } else {
                                    setEleValidCreds(false);
                                }
                                return data;
                            });
                    } else {
                        setEleHaveResults(true);
                        setEleValidCreds(true);
                        setEleFetching(false);
                    }
                }
            });
        }
    };

    const handleGasSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (gasUtilId && gasUsername && gasPassword) {
            setGasFetching(true);
            createUpdateUtilCreds({
                username: gasUsername.trim(),
                password: gasPassword,
                utility_id: gasUtilId,
            }).then((data) => {
                if (data.updated) {
                    if (gasUtilities?.get(gasUtilId)?.is_available) {
                        fetch_wrapper(`/verify-utility-credentials`, {
                            method: 'POST',
                            body: JSON.stringify({
                                utility_id: gasUtilId,
                            }),
                            credentials: 'include',
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                setGasFetching(false);
                                setGasHaveResults(true);
                                if (data.is_successful) {
                                    setGasValidCreds(true);
                                    scrapeHistoricalData(gasUtilId);
                                } else {
                                    setGasValidCreds(false);
                                }
                                return data;
                            });
                    } else {
                        setGasHaveResults(true);
                        setGasValidCreds(true);
                        setGasFetching(false);
                    }
                }
            });
        }
    };

    useEffect(() => {
        fetch_wrapper(`/get-available-utilities`, {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((data) => {
                const eleUtils: Map<string, Utility> = new Map<string, Utility>();
                const gasUtils: Map<string, Utility> = new Map<string, Utility>();
                for (const util of data['utilities']) {
                    if (util.type === 'ELECTRIC') {
                        eleUtils.set(util.id.toString(), util);
                    } else if (util.type === 'GAS') {
                        gasUtils.set(util.id.toString(), util);
                    }
                }
                setEleUtilities(eleUtils);
                setEleUtilId(eleUtils.keys().next().value);
                setGasUtilities(gasUtils);
                setGasUtilId(gasUtils.keys().next().value);
            })
            .catch((error) => console.log('error', error));
    }, []);

    useEffect(() => {
        fetch_wrapper(`/get-user-util-status`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((json) => JSON.parse(json))
            .then((data) => {
                if (data['electric']) {
                    setEleValidCreds(true);
                }
                if (data['gas']) {
                    setGasValidCreds(true);
                }
            })
            .catch((error) => console.log('error', error));
    }, []);

    const handleEditEleCreds = () => {
        setEleValidCreds(false);
        setEleHaveResults(false);
    };

    const handleEditGasCreds = () => {
        setGasValidCreds(false);
        setGasHaveResults(false);
    };

    return (
        <PageWrapper>
            {validSession && (
                <>
                    <div
                        id="util-creds-electric-container"
                        className="util-creds-electric-container"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            id="util-creds-electric-sub-container"
                            style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            {!validEleCreds && (
                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            height: '15%',
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontFamily: 'DunbarText, sans-serif',
                                                fontWeight: '400',
                                                fontSize: '34px',
                                            }}
                                        >
                                            Electricity Utility
                                        </div>
                                        <select
                                            style={{
                                                fontFamily: 'Basic',
                                                fontWeight: '400',
                                                fontSize: '20px',
                                                padding: '6px',
                                                borderRadius: '5px',
                                            }}
                                            value={eleUtilId}
                                            onChange={(e) => setEleUtilId(e.target.value)}
                                        >
                                            {eleUtilities &&
                                                Array.from(eleUtilities, ([utilId, utility]) => {
                                                    return (
                                                        <option
                                                            style={{
                                                                fontFamily: 'Basic',
                                                                fontWeight: '400',
                                                                fontSize: '20px',
                                                                padding: '6px',
                                                            }}
                                                            value={utilId}
                                                            key={utility.full_name}
                                                        >
                                                            {utility.full_name}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>
                                    <form
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            height: '75%',
                                            marginBottom: '0%',
                                        }}
                                        onSubmit={handleEleSubmit}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        fontFamily: 'Times, sans-serif',
                                                        fontWeight: '400',
                                                        fontSize: '16px',
                                                        marginBottom: '5px',
                                                    }}
                                                >
                                                    UTILITY USERNAME
                                                </div>

                                                {haveEleResults && !validEleCreds && !eleFetching && (
                                                    <div
                                                        style={{
                                                            color: 'red',
                                                            width: '46.1%',
                                                            textAlign: 'center',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Invalid Credentials
                                                    </div>
                                                )}
                                                {haveEleResults && validEleCreds && !eleFetching && (
                                                    <div
                                                        style={{
                                                            color: 'green',
                                                            textAlign: 'center',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Success
                                                    </div>
                                                )}
                                            </div>
                                            <input
                                                style={{
                                                    width: '346.5px',
                                                    height: '45px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    fontFamily: 'Basic',
                                                    fontSize: '24px',
                                                    paddingTop: '6px',
                                                    paddingBottom: '6px',
                                                    paddingLeft: '10px',
                                                    paddingRight: '10px',
                                                }}
                                                type="text"
                                                value={eleUsername}
                                                onChange={(e) => setEleUsername(e.target.value)}
                                            ></input>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        fontFamily: 'Times, sans-serif',
                                                        fontWeight: '400',
                                                        fontSize: '16px',
                                                        marginBottom: '5px',
                                                    }}
                                                >
                                                    UTILITY PASSWORD
                                                </div>
                                                {haveEleResults && !validEleCreds && !eleFetching && (
                                                    <div
                                                        style={{
                                                            color: 'red',
                                                            width: '46.1%',
                                                            textAlign: 'center',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Invalid Credentials
                                                    </div>
                                                )}
                                                {haveEleResults && validEleCreds && !eleFetching && (
                                                    <div
                                                        style={{
                                                            color: 'green',
                                                            textAlign: 'center',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Success
                                                    </div>
                                                )}
                                            </div>

                                            <input
                                                style={{
                                                    width: '346.5px',
                                                    height: '45px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    fontFamily: 'Basic',
                                                    fontSize: '24px',
                                                    paddingTop: '6px',
                                                    paddingBottom: '6px',
                                                    paddingLeft: '10px',
                                                    paddingRight: '10px',
                                                }}
                                                type="password"
                                                value={elePassword}
                                                onChange={(e) => setElePassword(e.target.value)}
                                            ></input>
                                        </div>
                                        <button
                                            type="submit"
                                            style={{
                                                fontSize: '20px',
                                                fontFamily: 'Basic',
                                                backgroundColor: !(eleUtilId && eleUsername.trim() && elePassword)
                                                    ? '#808080'
                                                    : '#000000',
                                                color: '#FFFFFF',
                                                width: '100px',
                                                height: '40px',
                                                border: '1px',
                                                borderRadius: '3px',
                                                cursor: !(eleUtilId && eleUsername.trim() && elePassword)
                                                    ? 'default'
                                                    : eleFetching
                                                    ? 'wait'
                                                    : 'pointer',
                                            }}
                                            disabled={!(eleUtilId && eleUsername.trim() && elePassword) || eleFetching}
                                        >
                                            {eleFetching ? 'LOADING...' : 'SUBMIT'}
                                        </button>
                                    </form>
                                </>
                            )}

                            {validEleCreds && (
                                <>
                                    <div
                                        className="utility_creds_received_message"
                                        style={{
                                            fontFamily: 'DunbarText, sans-serif',
                                        }}
                                    >
                                        We received your Electric Utility Credentials and are processing your data...
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            height: '30%',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontFamily: 'Times, sans-serif',
                                                fontWeight: '400',
                                                fontSize: '24px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            Did You Change Passwords or Provider?
                                        </div>
                                        <button
                                            style={{
                                                fontSize: '18px',
                                                fontFamily: 'Basic',
                                                backgroundColor: '#000000',
                                                color: '#FFFFFF',
                                                width: '260px',
                                                height: '40px',
                                                border: '1px',
                                                borderRadius: '3px',
                                                marginTop: '10px',
                                            }}
                                            onClick={handleEditEleCreds}
                                        >
                                            Edit your Electricity Credentials
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    <div
                        className="util-creds-seperator"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '1%',
                        }}
                    >
                        <div style={{ border: 'solid 1px black', width: '90%' }} />
                    </div>

                    <div
                        id="util-creds-gas-container"
                        className="util-creds-gas-container"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            id="util-creds-electric-sub-container"
                            style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            {!validGasCreds && (
                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            height: '15%',
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontFamily: 'DunbarText, sans-serif',
                                                fontWeight: '400',
                                                fontSize: '34px',
                                            }}
                                        >
                                            Gas Utility
                                        </div>
                                        <select
                                            style={{
                                                fontFamily: 'Basic',
                                                fontWeight: '400',
                                                fontSize: '20px',
                                                padding: '6px',
                                                borderRadius: '5px',
                                            }}
                                            value={gasUtilId}
                                            onChange={(e) => setGasUtilId(e.target.value)}
                                        >
                                            {gasUtilities &&
                                                Array.from(gasUtilities, ([utilId, utility]) => {
                                                    return (
                                                        <option
                                                            style={{
                                                                fontFamily: 'Basic',
                                                                fontWeight: '400',
                                                                fontSize: '20px',
                                                                padding: '6px',
                                                            }}
                                                            value={utilId}
                                                            key={utility.full_name}
                                                        >
                                                            {utility.full_name}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>
                                    <form
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            height: '75%',
                                            marginBottom: '0%',
                                        }}
                                        onSubmit={handleGasSubmit}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        fontFamily: 'Times, sans-serif',
                                                        fontWeight: '400',
                                                        fontSize: '16px',
                                                        marginBottom: '5px',
                                                    }}
                                                >
                                                    UTILITY USERNAME
                                                </div>

                                                {haveGasResults && !validGasCreds && !gasFetching && (
                                                    <div
                                                        style={{
                                                            color: 'red',
                                                            textAlign: 'center',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Invalid Credentials
                                                    </div>
                                                )}
                                                {haveGasResults && validGasCreds && !gasFetching && (
                                                    <div
                                                        style={{
                                                            color: 'green',
                                                            textAlign: 'center',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Success
                                                    </div>
                                                )}
                                            </div>

                                            <input
                                                style={{
                                                    width: '346.5px',
                                                    height: '45px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    fontFamily: 'Basic',
                                                    fontSize: '24px',
                                                    paddingTop: '6px',
                                                    paddingBottom: '6px',
                                                    paddingLeft: '10px',
                                                    paddingRight: '10px',
                                                }}
                                                type="text"
                                                value={gasUsername}
                                                onChange={(e) => setGasUsername(e.target.value)}
                                            ></input>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        fontFamily: 'Times, sans-serif',
                                                        fontWeight: '400',
                                                        fontSize: '16px',
                                                        marginBottom: '5px',
                                                    }}
                                                >
                                                    UTILITY PASSWORD
                                                </div>
                                                {haveGasResults && !validGasCreds && !gasFetching && (
                                                    <div
                                                        style={{
                                                            color: 'red',
                                                            textAlign: 'center',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Invalid Credentials
                                                    </div>
                                                )}
                                                {haveGasResults && validGasCreds && !gasFetching && (
                                                    <div
                                                        style={{
                                                            color: 'green',
                                                            textAlign: 'center',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Success
                                                    </div>
                                                )}
                                            </div>

                                            <input
                                                style={{
                                                    width: '346.5px',
                                                    height: '45px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    fontFamily: 'Basic',
                                                    fontSize: '24px',
                                                    paddingTop: '6px',
                                                    paddingBottom: '6px',
                                                    paddingLeft: '10px',
                                                    paddingRight: '10px',
                                                }}
                                                type="password"
                                                value={gasPassword}
                                                onChange={(e) => setGasPassword(e.target.value)}
                                            ></input>
                                        </div>
                                        <button
                                            type="submit"
                                            style={{
                                                fontSize: '20px',
                                                fontFamily: 'Basic',
                                                backgroundColor: !(gasUtilId && gasUsername.trim() && gasPassword)
                                                    ? '#808080'
                                                    : '#000000',
                                                color: '#FFFFFF',
                                                width: '100px',
                                                height: '40px',
                                                border: '1px',
                                                borderRadius: '3px',
                                                cursor: !(gasUtilId && gasUsername.trim() && gasPassword)
                                                    ? 'default'
                                                    : gasFetching
                                                    ? 'wait'
                                                    : 'pointer',
                                            }}
                                            disabled={!(gasUtilId && gasUsername.trim() && gasPassword) || gasFetching}
                                        >
                                            {gasFetching ? 'LOADING...' : 'SUBMIT'}
                                        </button>
                                    </form>
                                </>
                            )}

                            {validGasCreds && (
                                <>
                                    <div
                                        className="utility_creds_received_message"
                                        style={{
                                            fontFamily: 'DunbarText, sans-serif',
                                        }}
                                    >
                                        We received your Gas Utility Credentials and are processing your data...
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            height: '30%',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontFamily: 'Times, sans-serif',
                                                fontWeight: '400',
                                                fontSize: '24px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            Did You Change Passwords or Provider?
                                        </div>
                                        <button
                                            style={{
                                                fontSize: '18px',
                                                fontFamily: 'Basic',
                                                backgroundColor: '#000000',
                                                color: '#FFFFFF',
                                                width: '260px',
                                                height: '40px',
                                                border: '1px',
                                                borderRadius: '3px',
                                                marginTop: '10px',
                                            }}
                                            onClick={handleEditGasCreds}
                                        >
                                            Edit your Gas Credentials
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
            {!validSession && <div>Please Log In Before Adding User Data</div>}
        </PageWrapper>
    );
};

export default connect((state: RootState) => ({ validSession: state.session.validSession }))(
    UtilCredsPage,
);
