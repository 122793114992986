import { Button } from '@mui/material';
import * as sessionActions from '../../store/session';
import { useAppDispatch } from '../../store';
import { useNavigate } from 'react-router-dom';
import { fetch_wrapper } from '../../utils/fetch_wrapper';

const design_environment = process.env.REACT_APP_DESIGN_ENVIRONMENT;

const KelvinsTitleBox = ({ usingMobile }: { usingMobile: boolean }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleLogout = async () => {
        await fetch_wrapper(`/log-out-session`, {
            method: 'GET',
            credentials: 'include',
        });
        dispatch(sessionActions.sessionInvalidated());
        navigate('/');
    };

    return (
        <div
            id="KelvinsTitleBox"
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                backgroundColor: '#00000012',
                borderRadius: '30px',
                marginRight: '3.5%',
                height: '602px',
                width: '479px',
            }}
        >
            {design_environment === 'dev' && (
                <Button
                    variant="outlined"
                    style={{ fontSize: '18px', marginTop: '20px', backgroundColor: 'white' }}
                    onClick={() => handleLogout()}
                >
                    LogOut
                </Button>
            )}
            <img
                src="/logo.svg"
                alt=""
                width={usingMobile ? '300' : '297'}
                height={usingMobile ? '204.435' : '218'}
                onClick={() => window.open('https://www.wattsons.io', '_blank')}
            ></img>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    marginLeft: '10%',
                }}
            >
                <div style={{ fontFamily: 'DunbarText, sans-serif', fontSize: '50px' }}>
                    <span style={{ fontFamily: 'DunbarTextExBold' }}>H</span>
                    OME
                </div>
                <div style={{ fontFamily: 'DunbarText, sans-serif', fontSize: '50px' }}>
                    <span style={{ fontFamily: 'DunbarTextExBold' }}>E</span>
                    NERGY
                </div>
                <div style={{ fontFamily: 'DunbarText, sans-serif', fontSize: '50px' }}>
                    <span style={{ fontFamily: 'DunbarTextExBold' }}>A</span>
                    UDIT
                </div>
                <div style={{ fontFamily: 'DunbarText, sans-serif', fontSize: '50px' }}>
                    <span style={{ fontFamily: 'DunbarTextExBold' }}>T</span>
                    OOL
                </div>
            </div>
        </div>
    );
};

export default KelvinsTitleBox;
function country_fetch_wrapper(arg0: string, arg1: { method: string; credentials: string }) {
    throw new Error('Function not implemented.');
}
