import { TextField } from '@mui/material';

type props = {
    verificationCode: string;
    setVerificationCode: React.Dispatch<React.SetStateAction<string>>;
    verifSuccess: boolean | null;
};

const VerificationInput = ({ verificationCode, setVerificationCode, verifSuccess }: props) => {
    return (
        <>
            <TextField
                variant="outlined"
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                className="MuiTelInput-width-small"
                sx={{
                    '& .MuiOutlinedInput-root': {
                        height: '79px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '& .MuiInputBase-input': {
                            fontFamily: 'Basic',
                            fontSize: '32px',
                        },
                    },
                }}
            />
        </>
    );
};

export default VerificationInput;
