import { TextField } from '@mui/material';
import React, { FormEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper';
import { fetch_wrapper } from '../../utils/fetch_wrapper';

const sendUserAddress = (
    place: google.maps.places.PlaceResult,
    setValidAddress: React.Dispatch<React.SetStateAction<boolean | null>>,
    setUpdateSent: React.Dispatch<React.SetStateAction<boolean>>,
    setUpdated: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    let formattedAddress = place['formatted_address'];
    if (formattedAddress) {
        setValidAddress(true);
        fetch_wrapper(`/update-user-address`, {
            method: 'POST',
            body: JSON.stringify({
                address: formattedAddress,
            }),
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'success') {
                    setUpdated(true);
                } else {
                    setUpdated(false);
                }
                setUpdateSent(false);
                return data;
            });
    } else {
        setValidAddress(false);
    }
};

const UserInfoPage = ({ validSession }: { validSession: Boolean }) => {
    const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(null);
    const [updated, setUpdated] = useState<boolean>(false);
    const [updateSent, setUpdateSent] = useState<boolean>(false);
    const [validAddress, setValidAddress] = useState<boolean | null>(null);
    const navigate = useNavigate();

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (place) {
            setUpdateSent(true);
            sendUserAddress(place, setValidAddress, setUpdateSent, setUpdated);
            navigate('/utilcredsform');
        }
    };

    class GooglePlacesAutocomplete {
        private autocomplete: google.maps.places.Autocomplete;

        constructor(inputElement: HTMLInputElement) {
            this.autocomplete = new google.maps.places.Autocomplete(inputElement);
            this.setupListeners();
        }

        private setupListeners() {
            this.autocomplete.addListener('place_changed', () => {
                const place = this.autocomplete.getPlace();
                setPlace(place);
            });
        }
    }

    useEffect(() => {
        const inputElement = document.getElementById('autocompleteInput') as HTMLInputElement;
        if (inputElement && validSession) {
            new GooglePlacesAutocomplete(inputElement);
        }
    }, [validSession]);

    return (
        <PageWrapper>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                {validSession && (
                    <>
                        <div style={{ fontFamily: 'DunbarText, sans-serif', fontWeight: '400', fontSize: '50' }}>
                            ADDRESS FORM
                        </div>
                        <form
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '0px',
                                height: '37.5%',
                                width: '100%',
                            }}
                            autoComplete="off"
                            onSubmit={handleSubmit}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '95%',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            fontFamily: 'Times, sans-serif',
                                            fontWeight: '400',
                                            fontSize: '20px',
                                            marginBottom: '5px',
                                        }}
                                    >
                                        ADDRESS
                                    </div>
                                    {validAddress === false && (
                                        <div
                                            className="font-13"
                                            style={{
                                                color: 'red',
                                            }}
                                        >
                                            Invalid Address
                                        </div>
                                    )}
                                </div>
                                <TextField
                                    variant="outlined"
                                    autoComplete="off"
                                    type="text"
                                    id="autocompleteInput"
                                    className="MuiTelInput-width-small"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '79px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            '& .MuiInputBase-input': {
                                                fontFamily: 'Basic',
                                                fontSize: '24px',
                                            },
                                        },
                                    }}
                                />
                            </div>

                            <button
                                type="submit"
                                className="button-width-half"
                                style={{
                                    fontSize: '32px',
                                    fontFamily: 'Basic',
                                    backgroundColor: !place ? '#808080' : '#000000',
                                    color: '#FFFFFF',
                                    height: '79px',
                                    border: '1px',
                                    cursor: !place ? 'default' : updateSent ? 'wait' : 'pointer',
                                }}
                                disabled={!place}
                            >
                                {updateSent ? 'LOADING...' : 'SUBMIT'}
                            </button>
                        </form>
                    </>
                )}
                {!validSession && <div>Please Log In Before Adding User Data</div>}
            </div>
        </PageWrapper>
    );
};

export default connect((state: RootState) => ({ validSession: state.session.validSession }))(UserInfoPage);
