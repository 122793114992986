import React, { FormEvent, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import PhoneNumberInput from './PhoneNumberInput';
import { matchIsValidTel } from 'mui-tel-input';

type props = {
    setSecondsLeft: React.Dispatch<React.SetStateAction<number>>;
    resendSeconds: number;
    phoneNumber: string;
    setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
    phoneNumberSent: boolean;
    setPhoneNumberSent: React.Dispatch<React.SetStateAction<boolean>>;
    sendVerifText: () => void;
};

const PhoneNumberForm = ({
    setSecondsLeft,
    resendSeconds,
    phoneNumber,
    setPhoneNumber,
    setPhoneNumberSent,
    sendVerifText,
}: props) => {
    const [consent, setConsent] = useState(false);
    const [clicked, setClicked] = useState(false);

    const [validPhoneNumber, setValidPhoneNumber] = useState<boolean | null>(null);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setClicked(true);

        const isValid = matchIsValidTel(phoneNumber);

        setValidPhoneNumber(isValid);

        if (isValid && phoneNumber) {
            setPhoneNumberSent(true);
            sendVerifText();
        }

        setSecondsLeft(resendSeconds);
    };

    return (
        <form
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '0px',
                height: '37.5%',
            }}
            onSubmit={handleSubmit}
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                    style={{
                        display: 'flex',
                        width: '95%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            fontFamily: 'Times, sans-serif',
                            fontWeight: '400',
                            fontSize: '20px',
                            marginBottom: '5px',
                        }}
                    >
                        PHONE NUMBER
                    </div>
                    {!validPhoneNumber && validPhoneNumber !== null && (
                        <div
                            className="font-13"
                            style={{
                                color: 'red',
                            }}
                        >
                            Invalid Phone Number
                        </div>
                    )}
                </div>

                <PhoneNumberInput
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    validPhoneNumber={validPhoneNumber}
                />
            </div>

            <FormControlLabel
                onChange={() => setConsent(!consent)}
                control={<Checkbox />}
                style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                }}
                sx={{
                    '& .MuiFormControlLabel-label': {
                        fontFamily: 'Basic',
                        fontSize: '16px',
                    },
                }}
                label="I agree to receive communication regarding my account. Message and data rates may apply."
            />

            <button
                type="submit"
                className="button-width-full"
                style={{
                    fontSize: '24px',
                    fontFamily: 'Basic',
                    backgroundColor: phoneNumber.replace(/\s/g, '').length !== 12 || !consent ? '#808080' : '#000000',
                    color: '#FFFFFF',
                    height: '79px',
                    border: '1px',
                    cursor:
                        phoneNumber.replace(/\s/g, '').length !== 12 || !consent
                            ? 'default'
                            : clicked
                            ? 'wait'
                            : 'pointer',
                }}
                disabled={phoneNumber.replace(/\s/g, '').length !== 12 || !consent || clicked}
            >
                {clicked ? 'LOADING...' : 'START MY H.E.A.T. ASSESSMENT'}
            </button>
        </form>
    );
};

export default PhoneNumberForm;
