import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import * as sessionActions from '../../store/session';
import { useNavigate } from 'react-router-dom';
import PhoneNumberForm from '../../components/PhoneNumberForm';
import VerificationForm from '../../components/VerificationForm';
import { fetch_wrapper } from '../../utils/fetch_wrapper';

const resendSeconds = 30;

const LogInSignUpForm = ({ validSession }: { validSession: Boolean }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [secondsLeft, setSecondsLeft] = useState(resendSeconds);
    const [verifSent, setVerifSent] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberSent, setPhoneNumberSent] = useState(false);

    const handleLogout = async () => {
        await fetch_wrapper(`/log-out-session`, {
            method: 'GET',
            credentials: 'include',
        });
        dispatch(sessionActions.sessionInvalidated());
    };

    const sendVerifText = () => {
        fetch_wrapper(`/text-verification`, {
            method: 'POST',
            body: JSON.stringify({ phone_number: phoneNumber }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'success') {
                    setVerifSent(true);
                } else {
                    setPhoneNumberSent(false);
                }
                return data;
            });
    };

    useEffect(() => {
        if (!secondsLeft) return;

        const intervalId = setInterval(() => {
            setSecondsLeft(secondsLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [secondsLeft]);

    return (
        <>
            {validSession && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button
                        variant="outlined"
                        style={{ fontSize: '18px', marginTop: '20px' }}
                        onClick={() => navigate('/userinfo')}
                    >
                        User Info Form
                    </Button>
                    <Button
                        variant="outlined"
                        style={{ fontSize: '18px', marginTop: '20px' }}
                        onClick={() => navigate('/utilcredsform')}
                    >
                        Utility Credential Forms
                    </Button>
                    {/* <Button
                        variant="outlined"
                        style={{ fontSize: '18px', marginTop: '20px' }}
                        onClick={() => navigate('/userdata')}
                    >
                        User Data Visualization
                    </Button> */}
                    <Button
                        variant="outlined"
                        style={{ fontSize: '18px', marginTop: '20px' }}
                        onClick={() => handleLogout()}
                    >
                        LogOut
                    </Button>
                </div>
            )}

            {!validSession && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            marginBottom: '0px',
                            height: '58%',
                        }}
                    >
                        <div style={{ fontFamily: 'DunbarTextExBold, sans-serif', fontWeight: '500', fontSize: '16' }}>
                            FREE TO USE
                        </div>

                        <div style={{ fontFamily: 'DunbarText, sans-serif', fontWeight: '400', fontSize: '50' }}>
                            SEE HOW MUCH YOU CAN SAVE ON YOUR HOME ENERGY BILLS
                        </div>

                        <div style={{ fontFamily: 'Times, sans-serif', fontWeight: '400', fontSize: '24' }}>
                            Simply provide your phone number and we’ll quickly let you know if you’re optimizing your
                            home energy expenditures.
                        </div>
                    </div>

                    {!verifSent && (
                        <PhoneNumberForm
                            setSecondsLeft={setSecondsLeft}
                            resendSeconds={resendSeconds}
                            phoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                            phoneNumberSent={phoneNumberSent}
                            setPhoneNumberSent={setPhoneNumberSent}
                            sendVerifText={sendVerifText}
                        />
                    )}
                    {verifSent && (
                        <VerificationForm
                            secondsLeft={secondsLeft}
                            phoneNumber={phoneNumber}
                            sendVerifText={sendVerifText}
                            setSecondsLeft={setSecondsLeft}
                            resendSeconds={resendSeconds}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default connect((state: RootState) => ({ validSession: state.session.validSession }))(LogInSignUpForm);
