import React, { ReactNode } from 'react';

const ContentWrapper = ({ children, usingMobile }: { children: ReactNode; usingMobile: boolean }) => {
    return (
        <>
            {usingMobile && (
                <div
                    style={{
                        height: '18vh',
                        width: '100%',
                        display: 'flex',
                        marginBottom: '2vh',
                    }}
                >
                    <div
                        style={{
                            width: '100vw',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#00000012',
                        }}
                    >
                        <img
                            src="/logo.svg"
                            alt=""
                            height={'90px'}
                            onClick={() => window.open('https://www.wattsons.io', '_blank')}
                        />
                        <div
                            style={{
                                marginTop: '0.8vh',
                                fontSize: '16px',
                            }}
                        >
                            <span style={{ fontFamily: 'DunbarTextExBold', fontSize: '24px' }}>H</span>ome{' '}
                            <span style={{ fontFamily: 'DunbarTextExBold', fontSize: '24px' }}>E</span>nergy{' '}
                            <span style={{ fontFamily: 'DunbarTextExBold', fontSize: '24px' }}>A</span>udit{' '}
                            <span style={{ fontFamily: 'DunbarTextExBold', fontSize: '24px' }}>T</span>ool
                        </div>
                    </div>
                </div>
            )}
            <div
                id="ContentWrapper"
                style={{
                    width: usingMobile ? '90%' : '479px',
                    display: 'flex',
                    marginLeft: usingMobile ? '0%' : '3.5%',
                    height: usingMobile ? '80vh' : '602px',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                {children}
            </div>
        </>
    );
};

export default ContentWrapper;
