import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, } from 'react-router-dom';
import './App.css';
import * as sessionActions from './store/session';
import { useAppDispatch } from './store/index';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HomePage from './pages/HomePage';
import UtilCredsPage from './pages/UtilCredsPage';
import OAuthRedirectPage from './pages/OAuthRedirectPage';
import UserInfoPage from './pages/UserInfoPage';
import RegionTagHandler from './components/RegionTagHandler';

const theme = createTheme({
    palette: {
        primary: { main: '#000000' },
    },
});

function App() {
    const [isLoaded, setIsLoaded] = useState(true);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(sessionActions.validateSession());
    }, []);

    return (
        <ThemeProvider theme={theme}>
            {isLoaded && (
                <div className="App">
                    <Router>
                        {/* <RegionTagHandler> */}
                            <Routes>
                                <Route path="/" element={<HomePage />}></Route>
                                <Route path="/userinfo" element={<UserInfoPage />}></Route>
                                <Route path="/utilcredsform" element={<UtilCredsPage />}></Route>
                                {/* <Route path="/userdata" element={<UserDataVisualization />}></Route> */}
                                <Route path="/oauthredirect/:utility_slug" element={<OAuthRedirectPage />}></Route>
                            </Routes>
                        {/* </RegionTagHandler> */}
                    </Router>
                </div>
            )}
        </ThemeProvider>
    );
}

export default App;
